import React from "react";
import CTASection from "~/components/landing-page/cta-section";
import FeaturesSection from "~/components/landing-page/features-section";
import HeroSection from "~/components/landing-page/hero-section";
import PricingSection from "~/components/landing-page/pricing-section";
import type { NextPageWithLayout } from "~/pages/_app";
import LandingLayout from "~/components/layout/landing";
import NavBar from "~/components/layout/nav-bar";
import { NextSeo } from "next-seo";
import GPTZeroSection from "~/components/landing-page/gptzero-section";
import FAQSection from "~/components/landing-page/faq-section";

const LandingPage: NextPageWithLayout = () => {
  return (
    <div>
      <NextSeo
        title="Tweet Wizard"
        titleTemplate="%s"
        description="Generate valuable tweets using an innovative AI-assisted process."
        openGraph={{
          url: "https://tweetwizard.ai/",
          title: "Tweet Wizard",
          description:
            "Generate valuable tweets using an innovative AI-assisted process.",
          images: [
            {
              url: "https://tweetwizard.ai/img/og-home.png",
              width: 1200,
              height: 630,
              alt: "Tweet Wizard",
            },
          ],
          site_name: "Tweet Wizard",
        }}
      />
      <DynamicNavbar />
      <HeroSection />
      <FeaturesSection />
      <GPTZeroSection />
      <PricingSection />
      <FAQSection />
      <CTASection />
    </div>
  );
};

export default LandingPage;

LandingPage.getLayout = function getLayout(page) {
  return <LandingLayout>{page}</LandingLayout>;
};

const DynamicNavbar = () => {
  // this navbar should be invisible when scroll y = 0, and visible when scroll y > 0

  const [scrollY, setScrollY] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${scrollY > 50 ? "" : "-translate-y-16"} fixed z-[45] transition-transform will-change-transform`}
    >
      <NavBar />
    </div>
  );
};
