import React from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "../ui/dialog";

const WaitlistButton = ({ white = false }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={white ? "cta-white" : "cta"}>Join the waitlist</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <h2 className="text-2xl font-bold">Join the waitlist</h2>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            We are working hard to get our platform ready for you. Sign up to be
            the first to know when we launch.
          </p>
        </DialogHeader>
        <WaitlistForm />
        {/* <Script
          key={key.toString()}
          src="https://getlaunchlist.com/js/widget.js"
        /> */}
        <DialogClose asChild>
          <Button variant="cta-white">Close</Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default WaitlistButton;

const WaitlistForm = () => {
  let rendered = false;
  React.useEffect(() => {
    if (rendered) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    rendered = true;
    const script = document.createElement("script");
    script.src = "https://getlaunchlist.com/js/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      //remove all children from launchlist-widget-wrapper
      const wrapper = document.getElementById("launchlist-widget-wrapper");
      if (wrapper) {
        while (wrapper.firstChild) {
          wrapper.removeChild(wrapper.firstChild);
        }
      }
    };
  });
  return (
    <div
      id="launchlist-widget-wrapper"
      className="launchlist-widget h-[180px]"
      data-key-id="PzFo1N"
      data-height="180px"
    />
  );
};
