import React from "react";
import Image, { type StaticImageData } from "next/image";
import waitlistImage from "/public/img/undraw_checklist_white.svg";
import WaitlistButton from "./waitlist-button";

const CTASection = () => {
  return (
    <>
      <section className="bg-colorshift-light">
        <div className="container py-16 md:py-32">
          <div className="flex flex-col items-center justify-between gap-8 md:flex-row md:gap-16">
            <Image
              src={waitlistImage as StaticImageData}
              alt="Create screenshot"
              className="w-full max-md:max-w-64 md:w-96"
            />
            <div className="flex flex-col items-center justify-center gap-4 text-center">
              <h2 className="text-2xl font-extrabold tracking-tight text-white md:text-3xl">
                Join the waitlist today!
              </h2>
              <p className="text-balance font-semibold text-white">
                We offer a limited number of early access spots. Our first 100
                users will receive a{" "}
                <strong className="underline underline-offset-4">
                  50% discount forever
                </strong>
                .
              </p>
              <div className="pt-8">
                <WaitlistButton white />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CTASection;
