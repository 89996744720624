import React from "react";
import { Button } from "../ui/button";
import Link from "next/link";

const PricingSection = () => {
  return (
    <section className="bg-black/50 bg-[radial-gradient(80%_110%_at_50%_6rem,#1e40af_0%,#172554_100%)] py-16 md:py-32">
      <div className="container flex flex-col items-center justify-center gap-8">
        <h2 className="text-center text-2xl font-bold tracking-tight text-white md:text-3xl">
          We crafted pricing plans that fit different budgets and needs.
        </h2>
        <div className="text-balance text-center font-medium text-white">
          Check out our plans and choose the one that fits you best.
        </div>
        <Button className="mt-8" variant={"cta-white"} asChild>
          <Link href="/pricing">See Pricing</Link>
        </Button>
      </div>
    </section>
  );
};

export default PricingSection;
