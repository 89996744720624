import React from "react";
import WaitlistButton from "./waitlist-button";
import Image from "next/image";
// import heroImage from "/public/img/undraw_stepping_up.svg";
import NavMenu from "../layout/nav-menu";
import Logo from "/public/img/logo.png";
import createScreenshot from "/public/img/create-screenshot.png";
import scheduleScreenshot from "/public/img/schedule-screenshot.png";

const HeroSection = () => {
  return (
    <section className="bg-colorshift-dark">
      <nav className="container flex h-full items-center justify-between py-4 text-white">
        <div className="flex items-center gap-2 overflow-hidden text-xl font-extrabold sm:text-3xl">
          <Image src={Logo} alt="" priority className="h-12 w-12" /> TweetWizard
        </div>
        <div className="flex items-center gap-4">
          <NavMenu white />
        </div>
      </nav>
      <div className="container flex flex-col items-center justify-center py-16 max-md:gap-12 max-md:pt-8 md:flex-row-reverse md:py-36">
        <div className="flex w-[90vw] items-center justify-center sm:w-full md:w-[40rem] lg:w-[64rem]">
          <div className="card perspective-left relative z-10 w-full rounded-lg p-1 sm:p-2 ">
            <Image
              src={createScreenshot}
              alt="Create screenshot"
              className="rounded-md"
              priority
            />
          </div>
          <div className="card perspective-right relative z-10 -ml-8 w-full rounded-lg p-1 sm:p-2 md:-ml-6 lg:-ml-10">
            <Image
              src={scheduleScreenshot}
              alt="Create screenshot"
              className="rounded-md"
              priority
            />
          </div>
        </div>
        <div className="container flex w-full flex-col items-center justify-center gap-8 text-center sm:gap-16 lg:gap-24">
          <h1 className="text-balance text-3xl font-bold tracking-tight text-transparent text-white sm:text-4xl lg:text-5xl">
            Generate valuable ideas.
            <br />
            Schedule tweets.
            <br />
            Grow your brand.
          </h1>
          <p className="text-balance font-semibold text-white">
            With our innovative AI-assisted process, grow your audience on{" "}
            <span className="text-lg">𝕏</span> (Twitter) with ease and
            confidence. Eliminate writer&apos;s block and get more done in less
            time.
          </p>
          <WaitlistButton white />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
