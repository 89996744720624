import Head from "next/head";
import NavBar from "./nav-bar";

type LandingLayoutProps = {
  children: React.ReactNode;
};

const LandingLayout = ({ children }: LandingLayoutProps) => {
  return (
    <div className="flex min-h-screen flex-col text-base text-indigo-950">
      <Head>
        <title>Tweet Wizard</title>
      </Head>
      <main className="grow">{children}</main>
      <footer className="bg-slate-800 text-start text-white">
        <div className="container flex flex-col gap-2 py-8 text-sm">
          © Waleed Salama 2024. All rights reserved.
          <a
            href="https://x.com/TweetWizard_AI"
            target="_blank"
            rel="noopener noreferrer"
            className="flex h-8 w-8 items-center justify-center rounded-full border border-indigo-50 text-xl text-indigo-50 transition-colors duration-150 hover:border-indigo-500 hover:bg-white hover:text-indigo-800"
          >
            𝕏
          </a>
        </div>
      </footer>
    </div>
  );
};

export default LandingLayout;
