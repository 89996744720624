const faqs: {
  question: string;
  answer: string;
}[] = [
  {
    question: "What is TweetWizard?",
    answer:
      "TweetWizard is an innovative platform designed to help you grow your audience on Twitter with ease and confidence. Our AI-assisted process enables you to generate valuable ideas and tweets, schedule tweets, and ultimately grow your brand.",
  },
  {
    question: "How does TweetWizard work?",
    answer:
      "TweetWizard utilizes AI technology to guide you through the process of generating ideas tailored to your specific target audience. You can then use these ideas to create engaging tweets, which can be scheduled for posting at optimal times.",
  },
  {
    question: "What sets TweetWizard apart from other social media tools?",
    answer:
      "TweetWizard's process sets it apart from other social media tools by focusing on generating valuable content ideas that resonate with your audience. We ask you to fill a very short form once – which can also be AI-assisted – and you can generate endless ideas.\n\nTweetWizard stands out thanks to its AI model, which is trained on a large dataset of viral tweets to generate human-like and engaging content. Our platform also offers features such as long-form tweets, thread creation, and personalized support to help you maximize your Twitter presence.",
  },
  {
    question: "Can I try TweetWizard before committing to a plan?",
    answer:
      "Yes, you can join our waitlist to be notified when we offer early access spots. Additionally, our first 100 users will receive a 50% discount forever, so it's a great opportunity to try out our platform at a discounted rate.",
  },
  {
    question: "Is TweetWizard suitable for individuals or teams?",
    answer:
      "TweetWizard caters to both individuals and teams. Whether you're a solo user looking to manage your personal brand or a team collaborating on multiple accounts, our platform offers plans and features tailored to your needs.",
  },
  {
    question: "How secure is my data on TweetWizard?",
    answer:
      "We take the security and privacy of your data seriously. TweetWizard employs industry-standard encryption protocols and follows best practices to ensure that your information is protected at all times.",
  },
  {
    question: "What kind of support does TweetWizard offer?",
    answer:
      "TweetWizard provides comprehensive support to assist you with any questions or issues you may encounter. From chat support within the platform to dedicated account managers for team accounts, we're here to help you succeed on Twitter.",
  },
  {
    question: "Can I customize the content generated by TweetWizard?",
    answer:
      "Absolutely! While TweetWizard offers AI-generated suggestions to streamline your content creation process, you have full control to edit, tweak, or create content from scratch according to your preferences.",
  },
  {
    question: "How do I sign up for TweetWizard?",
    answer:
      "Signing up for TweetWizard is easy! Simply visit our website and join the waitlist to be notified when early access spots become available. Once you're onboarded, you can choose a plan that fits your needs and start growing your Twitter presence.",
  },
  {
    question:
      "Where can I find more information about TweetWizard's pricing plans?",
    answer:
      "You can visit our Pricing page to explore our different plans, features, and pricing options. If you have specific questions about pricing or need assistance in choosing the right plan, our support team is always available to help.",
  },
];
export default faqs;
