import React from "react";
import faqs from "./faqs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui/accordion";

const FAQSection = () => {
  return (
    <section className="bg-white py-20">
      <div className="container flex flex-col items-center justify-center gap-12">
        <h2 className="text-center text-2xl font-bold tracking-tight text-purple-700 md:text-3xl">
          Frequently Asked Questions
        </h2>
        <div className="max-w-prose text-balance text-center font-medium text-purple-900">
          Got questions? We&apos;ve got answers!
        </div>
        <Accordion type="single" collapsible className="w-full max-w-prose">
          {faqs.map((faq) => (
            <AccordionItem key={faq.question} value={faq.question}>
              <AccordionTrigger className="text-start hover:text-indigo-600 hover:no-underline">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className="whitespace-pre-wrap">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default FAQSection;
