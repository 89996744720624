import React from "react";
import Image from "next/image";
import zeroGPTImage from "/public/img/zerogpt.png";
import copyLeaksImage from "/public/img/copyleaks.png";

const GPTZeroSection = () => {
  return (
    <section className="bg-gradient-to-b from-slate-200 via-white to-slate-200 py-16 md:py-32">
      <div className="container flex flex-col items-center justify-center gap-12">
        <h2 className="text-colorshift text-balance text-center text-2xl font-bold tracking-tight md:text-3xl">
          Generate{" "}
          <span className="relative z-20 text-nowrap text-white">
            human-like
            <div className="bg-colorshift-light absolute -inset-1 -z-10 -rotate-1" />
          </span>{" "}
          and engaging tweets!
        </h2>
        <p className="text-balance text-center font-medium">
          Our AI model is trained on a large dataset of viral tweets to generate
          human-like and engaging content. We even tested the output with
          ZeroGPT and CopyLeaks and the results were impressive!
        </p>
        <div className="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
          <div className="card bg-colorshift-light rounded-lg p-2">
            <Image
              src={zeroGPTImage}
              alt="Create screenshot"
              placeholder="blur"
              className="rounded-md"
            />
          </div>
          <div className="card bg-colorshift-light rounded-lg p-2">
            <Image
              src={copyLeaksImage}
              alt="Create screenshot"
              placeholder="blur"
              className="rounded-md"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GPTZeroSection;
