import React from "react";
import Image from "next/image";
import createScreenshotIdea from "/public/img/create-screenshot-idea.png";
import scheduleScreenshot from "/public/img/schedule-screenshot.png";
import tweetScreenshot from "/public/img/create-screenshot-tweet.png";

const FeaturesSection = () => {
  return (
    <section className="flex flex-col gap-16 bg-white py-16 md:gap-32 md:py-32">
      <div className="container">
        <div className="flex flex-col items-center justify-between gap-8 md:flex-row-reverse md:gap-16">
          <div className="flex w-full flex-col max-md:text-center">
            <h2 className="text-colorshift text-2xl font-bold tracking-tight md:text-3xl">
              Bespoke Idea Generation.
            </h2>
            <p className="text-balance">
              Our AI-assisted process guides you to generate infinite ideas that
              add value to your specific target audience. You also have the
              options to edit the idea or add your own ideas.
            </p>
          </div>
          <div className="card bg-colorshift-light w-full rounded-lg p-2 md:w-[36rem]">
            <Image
              src={createScreenshotIdea}
              alt="Create screenshot"
              placeholder="blur"
              className="rounded-md"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="flex flex-col items-center justify-between gap-8 md:flex-row md:gap-16">
          <div className="flex w-full flex-col max-md:text-center">
            <h2 className="text-colorshift text-2xl font-bold tracking-tight md:text-3xl">
              Generate Tweets.
            </h2>
            <p className="text-balance">
              Generate tweets based on the ideas you have created. You can
              review the tweet and tweak it, or even write it from scratch
              without guidance. You can choose to post it immediately or
              schedule it for later.
            </p>
          </div>
          <div className="card bg-colorshift-light w-full rounded-lg p-2 md:w-[36rem]">
            <Image
              src={tweetScreenshot}
              alt="Schedule screenshot"
              placeholder="blur"
              className="w-full rounded-md"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="flex flex-col items-center justify-between gap-8 md:flex-row-reverse md:gap-16">
          <div className="flex w-full flex-col max-md:text-center">
            <h2 className="text-colorshift text-2xl font-bold tracking-tight md:text-3xl">
              Schedule Tweets.
            </h2>
            <p className="text-balance">
              Be consistent with your tweets by scheduling them in advance. Our
              process will guide you to set a consistent weekly schedule so that
              you can easily fill with valuable tweets.
            </p>
          </div>
          <div className="card bg-colorshift-light w-full rounded-lg p-2 md:w-[36rem]">
            <Image
              src={scheduleScreenshot}
              alt="Schedule screenshot"
              placeholder="blur"
              className="w-full rounded-md"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
